/***

  BLU-J STYLES

  PRIMARY:
  - Blue: #9ab7c0;
  - Secondary: #0f3737;
  - Pink: #ff63c7;


  RAG:
  - rgba(255,0,0,0.7);
  - rgba(255,165,0,0.7);
  - rgba(0,128,0,0.7);

  RESPONSIVE
  TABS & FORMS

***/

/** Ionic CSS Variables **/
:root {
  /** primary - blue **/
  --ion-color-primary: #9ab7c0;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #9ab7c0;
  --ion-color-primary-tint: #9ab7c0;

  /** secondary - green **/
  --ion-color-secondary: #0f3737;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0f3737;
  --ion-color-secondary-tint: #0f3737;

  /** strapline - pink **/
  --ion-color-pink: #ff63c7;
  --ion-color-pink-rgb: 61, 194, 255;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 255, 255, 255;
  --ion-color-pink-shade: #ff63c7;
  --ion-color-pink-tint: #ff63c7;

  /** tertiary **/
  --ion-color-tertiary: #9ab7c0;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #9ab7c0;
  --ion-color-tertiary-tint: #9ab7c0;

  /** success **/
  --ion-color-success: #008000;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #008000;
  --ion-color-success-tint: #008000;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** bluj overrides **/
  --bluj: #9ab7c0;
  --ion-toolbar-background: #9ab7c0;
  // --ion-toolbar-color: white;
  --ion-backdrop-opacity: 0.6;
}

@font-face { font-family: ‘helvetica neue’; src: local(‘Arial’); }
@font-face { font-family: ‘helvetica neue’; font-weight:bold; src: local(‘Arial’); }
@font-face { font-family: ‘helvetica’; src: local(‘Arial’); }

.ios body {
  --ion-background-color: #ffffff;
}
.ios body {
  --ion-background-color: #ffffff;
}
.toast-truce .toast-container {

}

/* RESPONSIVE - MOBILE */
@media only screen and (max-device-width: 999px) {
  .learn-more.mobile {
    display: block !important;
  }
  .learn-more.desktop {
    display: none !important;
  }
  .mob-hidden {
    display: none !important;
  }
  /* TOOLBAR ETC */
  ion-header .top-toolbar {
    margin-left: 0 !important;
    max-width: 300px;
    flex: auto;
  }
  ion-header .top-toolbar ion-col {
    min-width: 0;
  }
  ion-header .profile-photo {
    display: inline-block !important;
    margin-left: 15px;
  }
  ion-header .logo {
    display: grid !important;
    align-items: center;
  }
  ion-header .logo ion-img {
    width: 150px !important;
    height: 46px !important;
    margin-left: 15px !important;
  }
  ion-header .hamburger {
    display: block !important;
  }
  .toolbar.left {
    display: none;
  }
  ion-toolbar,
  .helpbot-icon,
  .modal_helpbot,
  ion-header .version,
  ion-header .profile-container,
  .toolbar.right {
    display: none !important;
  }
  .breadcrums {
    margin: 15px 12px 0 !important;
    min-height: 80px !important;
  }
  .breadcrums ion-col:first-child {
    flex: 100 !important;
  }
  .breadcrums ion-col:first-child span,
  .breadcrums ion-col:first-child fa-icon,
  .breadcrums ion-col .learn-more {
    font-size: 18px !important;
  }
  /* MAIN LAYOUT */
  .main-container {
    height: unset !important;
  }
  .dashboard-top {
    margin-top: 10px !important;
  }
  .tab-dashboard {
    padding: 0 5px !important;
  }
  .menu-tab {
    padding: 10px 0 !important;
  }
  /* DASHBOARD */
  .dash-message {
    margin-bottom: -30px !important;
  }
  .dashboard-gauges .gauge-container {
    width: 50% !important;
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
  .activity-interactions .activity,
  .activity-interactions .interactions,
  .mobile-full {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .mobile-third {
    width: 33% !important;
    max-width: 33% !important;
    flex: 0 0 33% !important;
  }
  /* WIN THEMES */
  .theme_library {

  }
  .login .welcome-message {
    margin: 0 !important;
  }
  .login .saved-session-container,
  .login .heading-subtitle,
  app-register .heading-subtitle,
  app-subscription .heading-subtitle,
  app-payment .heading-subtitle,
  app-payment .stripe {
    display: none;
  }
  .login .home_login_container {
    margin: 0 20px;
  }
  .login .heading-title,
  app-register .heading-title,
  app-subscription .heading-title,
  app-payment .heading-title,
  app-testimonial .heading-title {
    font-size: 40px;
  }
  .login .left-container {
    padding: 10px 20px !important;
  }
  app-forgot-password ion-grid {
    margin-top: calc(50vh - 125px) !important;
    padding: 0 20px;
  }
  app-register .register_image_container {
    padding: 0;
    margin: 10px 0;
  }
  .register_container {
    margin: 0 15px;
    margin-bottom: 20px;
  }
  app-register .logo {
    margin: 0 auto !important;
  }
  app-subscription .level {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-top: 20px;
  }
  app-payment .left-container,
  app-payment .login_container {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    padding: 0 20px;
  }
  app-payment .left-container {
    padding-bottom: 0;
  }
  app-payment ion-grid {
    padding: 0 15px;
  }
  app-payment .login_container {
    margin-bottom: 20px;
  }
  app-testimonial .logo {
    width: 200px !important;
  }
  app-testimonial ion-grid {
    margin: 0 20px !important;
  }
  app-testimonial .heading-subtitle {
    font-size: 25px !important;
  }
}

/* RESPONSIVE - TABLET */
@media only screen and (min-device-width: 768px) and (max-device-width: 999px) {
  .toolbar.right,
  ion-toolbar {
    display: block !important;
  }
  ion-toolbar .session-profile {
    flex: 0 0 30.0% !important;
    max-width: 30.0% !important;
  }
  ion-toolbar .menu-items {
    flex: 0 0 40.0% !important;
    max-width: 40.0% !important;
  }
  ion-toolbar .menu-items ion-col {
    flex: 0 0 20.0% !important;
    max-width: 20.0% !important;
  }
  ion-toolbar .branding {
    flex: 0 0 30.0% !important;
    max-width: 30.0% !important;
  }
}

/* RESPONSIVE - LAPTOP */
@media only screen and (min-device-width: 1000px) and (max-device-width: 1250px) {
  .learn-more.mobile {
    display: block !important;
  }
  .learn-more.desktop {
    display: none !important;
  }
  .toolbar.left {
    min-width: unset !important;
  }
  .toolbar.left .inner {
    width: 125px !important;
    min-width: unset !important;
  }
  .toolbar.left .resizer,
  .toolbar.left .resizer .fa-chevron-circle-left,
  .toolbar.left .heading,
  .toolbar.left .beta,
  .left-meta {
    display: none;
  }
  .toolbar.right {
    width: 350px !important;
  }
  .toolbar.right.lite {
    width: unset !important;
  }
  ion-toolbar {
    font-size: 14px !important;
  }
  /* ion-toolbar ion-col:last-child {
    padding: 10px !important;
  } */
  .breadcrums {
    margin: 30px 0 0 !important;
  }
  .tab-dashboard {
    padding: 0 !important;
  }
}

/* RESPONSIVE - DESKTOP */
@media only screen and (min-device-width: 1000px) {
  .learn-more.mobile {
    display: none !important;
  }
  .learn-more.desktop {
    display: block !important;
  }
  .toolbar-tooltip {
    display: none !important;
  }
}

.col-1-5 {
  flex: 0 0 12.3%;
  max-width: 12.3%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* FROM WEBISTE */
.heading-title {
  color: #2C2C51;
  font-size: 55px;
  font-weight: 800;
  /* line-height: 2em; */
}
.heading-subtitle {
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
}

.home_login_container {
  height: 100%;
  padding: 20px 50px 50px 50px;
  background-color: whitesmoke;
  text-align: center;
  text-align: -webkit-center;
  border-radius: 10px;
}
.home_divider {
  height: 20px;
  border-radius: 25px;
  border-width: 1px;
  border-color:darkgrey;
  border-style: solid;
  background-color: darkgrey;
  width: 200px;
}
.home_user_container {
  height: 200px;
  border-radius: 15px;
  border-width: 1px;
  border-color:whitesmoke;
  border-style: solid;
  background-color: whitesmoke;
  margin: 5px;
}
.home_user_container .inner {
  height: 100%;
}
.home_user_container .profile-photo {
  margin-top: 25px;
}
.home_user_container .profile-name {
  margin-top: 10px;
}
.home_user_button {
  --border-radius: 10px;
  height: 50px;
  width: 250px;
  --background: whitesmoke;
  --color: grey;
  font-size: 20px;
}
.container_left {
  padding: 50px 50px 0px 50px;
  background-color: whitesmoke;
  text-align: center;
  text-align: -webkit-center;
  border-width: 1px;
  border-color:grey;
  border-style: solid;
  border-right-style: none;
}
.container_right {
  padding: 20px 50px 60px 50px;
  background-color: whitesmoke;
  text-align: center;
  text-align: -webkit-center;
  border-width: 1px;
  border-color:grey;
  border-style: solid;
  border-left-style: none;
}
.login_container {
  padding: 20px 50px 60px 50px;
  background-color: whitesmoke;
  text-align: center;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31), 0 20px 32px -8px rgba(9, 30, 66, 0.25);
  text-align: -webkit-center;
  border-width: 1px;
  border-color:grey;
  border-style: solid;
  border-radius: 10px;
}
.login_container.full {
  height: 100%;
}
.admin_container {
  padding: 20px 20px 20px 20px;
  background-color: white;
  text-align: center;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31), 0 20px 32px -8px rgba(9, 30, 66, 0.25);
  text-align: -webkit-center;
  border-width: 2px;
  border-color:grey;
  border-style: solid;
  border-radius: 10px;
}
.admin_container_grey {
  padding: 40px 60px 20px 60px;
  background-color: whitesmoke;
  text-align: center;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31), 0 20px 32px -8px rgba(9, 30, 66, 0.25);
  text-align: -webkit-center;
  border-width: 2px;
  border-color:grey;
  border-style: solid;
  border-radius: 10px;
}
.signup_button {
  --border-radius: 10px;
  --border-width: 2px;
  --border-color: white;
  --border-style: solid;
  --background: darkgrey;
  height: 50px;
  width: 150px;
  font-weight: bold;
}
ion-button.full {
  width: 100%;
  margin: 0;
  max-height: 45px;
}
.login_button {
  --border-radius: 10px;
  height: 50px;
  --background: darkgrey;
  font-weight: bold;
}
ion-button.primary {
  --background: #9ab7c0;
  color: white;
}
ion-button.pink {
  --background: #ff63c7;
  color: white;
}
ion-button span {
  line-height: 1.4;
}
.signup_button_alternative {
  max-width: 200px;
  width: 200px;
  --background: #ff63c7 !important;
  --border-radius: 40px !important;
  --padding: 30px 0 !important;
  min-height: 70px;
  font-size: 16px !important;
  --box-shadow: none !important;
}
.toolbar.right .signup_button_alternative {
  min-height: 25px !important;
}
.login_button,
.signup_button_alternative {
  font-weight: bolder;
  font-size: 18px;
}
.round_blue_button {
  --border-radius: 100%;
  height: 50px;
  --background: #9ab7c0;
}
.round_blue_img {
  border-radius: 100%;
  height: 50px;
  background: #9ab7c0;
}
.round_blue_input_card {
  border-radius: 100%;
  margin-bottom: 10px;
  height: 200px;
  width: 200px;
}
.round_blue_input_card_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.choose_input_background {
  position: absolute;
  opacity: 0;
  width: 40%;
  height: 25px;
}
.choose_input_background_big {
  position: absolute;
  opacity: 0;
  width: 40%;
  height: 50px;
}
.choose_input_background.full {
  width: 100%;
  height: 100%;
}
.choose_input_background.top {
  top: 0;
}
.choose_input_background.full input {
  width: 100%;
  height: 100%;
}
.choose_photo_background {
  opacity: 0;
  position: relative;
  margin-top: -50px;
}
.choose_photo_background.full {
  height: 100%;
  top: 45px;
  position: absolute;
  cursor: pointer;
}
.choose_input {
  color: white;
  cursor: pointer;
  height: 100%;
  width: 100%;
  max-width: 200px;
  background: #ff63c7 !important;
  border-radius: 40px !important;
  padding: 30px 0 !important;
  min-height: 70px;
  font-weight: bold;
}
input[type="file"] {
  cursor: pointer;
}
.settings_button {
  --border-radius: 5px;
  background: whitesmoke;
  color: grey;
  cursor: pointer;
  --background: whitesmoke;
  font-size: 15px;
}
.ion_item_input {
  --border-radius: 5px;
  --border-width: 2px;
  --border-color:#9ab7c0;
  --background: white;
  width: 100%;
}
.ion_item_separator {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: white;
}
.content-tab {
  background: transparent;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.content-tab.selected {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: white;
  border: 3px solid #9ab7c0;
  border-bottom: none;
}
.content-tab.selected span {
  font-weight: bold;
}
.ion_item_separator_grey {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: white;
  --background: white;
  border: 3px solid #9ab7c0;
  border-bottom: none;
}
.ion_item_separator_admin_grey {
  text-align: center;
}
.ion_item_separator_admin_grey.active {
  text-align: center;
  border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    background: white;
    border-bottom: 0px;
}
.top-toolbar ion-col {
  min-width: 80px;
  cursor: pointer; 
  margin-right: 15px;
  color: white;
}
.top-toolbar ion-col fa-icon {
  color: white;
}
.has-header .name {
  font-weight: 600;
  font-size: 22px;
}
.menu_separator {
  border-width: 1.5px;
  border-color: whitesmoke;
  border-style: solid;
}
.tab-dashboard {
  height: 100%; 
  padding: 0 25px 25px 25px;
}
.row_separator {
  border-width: 1px;
  border-color: darkgray;
  border-style: solid;
}
.input_box {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  height: 100%;
  text-align: center;
}
.search_button {
  --border-radius: 10px;
  --border-width: 1px;
  --border-color: grey;
  --background: white;
  --border-style: solid;
  padding: 0px;
}
.input_edit_button {
  --border-width: 2px;
  --border-color: grey;
  --border-style: solid;
  --background: whitesmoke;
  background-color: whitesmoke;
  color: grey;
  padding-right: 10px;
  height: 20px;
}
.input_select {
  /*padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-left: 20px !important;
  background-color: white;
  height: 100%;
  width: 100%;
  --padding-end: 10px;*/

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('/assets/icon/arrow-down.png');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: calc(50% - 2px);
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  background-size: 30px;
  cursor: pointer;
}
.input_toggle {
  background-color: whitesmoke;
  padding-top: 20px;
  padding-bottom: 20px;
}
ion-item{
  --inner-padding-end: 0px;
  --padding-start:0px;
}
.register_image_container {
  padding: 20px 50px 60px 50px;
  background-color: darkgrey;
  text-align: center;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31), 0 20px 32px -8px rgba(9, 30, 66, 0.25);
  text-align: -webkit-center;
  border-width: 1px;
  border-color:grey;
  border-style: solid;
  margin-right: 40px;
}
.register_container {
  padding: 20px 50px 60px 50px;
  background-color: whitesmoke;
  text-align: center;
  text-align: -webkit-center;
  border-width: 1px;
  border-color:grey;
  border-style: solid;
  margin-right: 20px;
  border-radius: 5px;
}
.register_container ion-input {
  border: none;
}

.wintheme_container {
  /*background-color: white;*/
  text-align: center;
  text-align: -webkit-center;
  border-width: 5px;
  border-color:grey;
  border-style: solid;
  border-radius: 10px;
  border: 2px solid #9ab7c0;
  box-shadow: 0px 1px 5px 0px #9ab7c0;
}
.wintheme_container.featured {
  background: white;
}
.wintheme_container.featured p {
  padding: 0 15px;
}
.themes-repeat:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wintheme_container.p_wintheme_container {
  border: none;
  box-shadow: none;
}
.wintheme_container.content-container,
.wintheme_container.wintheme {
  padding: 20px;
}
.content_html {
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  height: 80px !important;
  overflow: scroll;
}

.wintheme_container.green {
  border: 2px solid green;
  box-shadow: 0px 1px 5px 0px green;
}
.wintheme_container.green fa-icon,
.wintheme_container.green fa-icon svg,
.wintheme_container.green .match {
  color: green !important;
}
.wintheme_container.green .content_image_container {
  border: 2px solid green;
}

.wintheme_container.orange {
  border: 2px solid orange;
  box-shadow: 0px 1px 5px 0px orange;
}
.wintheme_container.orange fa-icon,
.wintheme_container.orange fa-icon svg,
.wintheme_container.orange .match {
  color: orange !important;
}
.wintheme_container.orange .content_image_container {
  border: 2px solid orange;
}

.wintheme_container.red {
  border: 2px solid red;
  box-shadow: 0px 1px 5px 0px red;
}
.wintheme_container.red fa-icon,
.wintheme_container.red fa-icon svg,
.wintheme_container.red .match {
  color: red !important;
}
.wintheme_container.red .content_image_container {
  border: 2px solid red;
}

.wintheme_container.content_container {
  margin-top: 30px;
  margin-bottom: 50px;
  cursor: pointer;
  background-color: white;
}

.wintheme_container.not-found {
  padding: 10px 10px 20px;
  border-width: 4px;
  max-width: 750px !important;
  border: 1px solid #9ab7c0;
  background: white;
}
.google_search.not-found {
  max-width: 1200px !important;
  margin: auto;
}
.content_image_container {
  background-color: white;
  /*text-align: center;
  text-align: -webkit-center;*/
  text-align: left;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  border: 2px solid #9ab7c0;
}
.bg-whitesmoke {
  --background: whitesmoke;
  background: whitesmoke;
}
.full-button {
  width: 100%;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.align-center {
  align-items: center;
}
.grid-center {
  align-items: center;
  display: grid;
}
.float-left {
  float: left;
}
.text-right {
  text-align: right !important;
}
.float-right {
  float: right;
}
.block {
  display: block;
}
.nopad {
  padding: 0;
}

/* main containers */
.main-container {
  height: calc(100% - 120px); 
  padding: 0px;
}
.main-container-inner {
  height: 100%;
}
.has-header {

}
.toolbar {
  background-color: whitesmoke;
}
.toolbar.left {
  padding-right: 25px;
  min-width: 350px; // !important
  padding-left: 25px;
  padding-top: 25px;
}
.toolbar.left .inner,
.toolbar.right .inner {
  margin-bottom: 0px;
  min-width: 350px;
  border-radius: 15px;
  background: white;
  padding-bottom: 25px;
  min-height: calc(100vh);
}
.toolbar.right .inner {
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
}
.toolbar.left fa-icon {
  cursor: pointer;
}
.toolbar.left.lite {
  min-width: unset !important;
}
.toolbar.left.lite .inner {
  width: 125px !important;
  min-width: unset !important;
}
.toolbar.left.lite .resizer .fa-chevron-circle-left {
  display: none; 
}
.toolbar.left .resizer {
  position: absolute;
  right: 8px;
  top: calc(50vh);
  z-index: 999;
  cursor: pointer;
  background: white;
  border-radius: 50px;
}
.toolbar.left.lite .resizer {
  left: 130px;
  right: unset;
}
.toolbar.left.lite .resizer .fa-chevron-circle-right {
  display: block; 
}
.toolbar.left .resizer .fa-chevron-circle-right {
  display: none; 
}

.toolbar.left.lite .heading,
.toolbar.left.lite .beta {
  display: none;
}
.toolbar.right {
  padding-left: 25px;
  padding-top: 25px;
  min-width: 400px; // !important
  padding-right: 25px;
}
.toolbar.right .resizer {
  position: absolute;
  left: -30px;
  top: calc(50vh);
  z-index: 999;
  cursor: pointer;
  background: white;
  border-radius: 50px;
}
.toolbar.right .resizer .fa-chevron-circle-left {
  display: none; 
}
.toolbar.right.lite .resizer .fa-chevron-circle-left {
  display: block; 
}
.toolbar.right.lite .resizer .fa-chevron-circle-right {
  display: none; 
}
.toolbar.right.lite {
  padding-top: 25px;
  min-width: 125px !important;
  text-align: center;
}
.toolbar.right.lite ion-row.main {
  display: none !important;
}
.toolbar.right.lite .inner {
  min-width: unset;
  padding-right: 25px;
  padding-top: 40px;
}
.toolbar ion-button {
  min-width: 90px !important;
  margin-left: -2px;
}
.toolbar .empty-area {
  margin-top: -10px;
  font-style: italic;
}
.alert-counts {

}

.search_page.wintheme_container .wintheme_container {
  background: white;
}
.quick_search {
  text-align: center;
  border-radius: 10px;
  margin: 20px 15px 0;;
  font-size: 20px;
  border: 2px solid #9ab7c0;
  box-shadow: 0px 1px 5px 0px #9ab7c0;
  min-height: 80px;
  align-items: center;
  margin: auto;
}
.quick_search.google_search {
  margin-bottom: 25px;
  background: white;
}
.quick_search .searchbar {
  border-bottom: 1px solid #ccc;
}
.quick_search ion-searchbar {
  padding: 0;
}
.quick_search .searchbar-input {
  border: none;
  box-shadow: none;
  font-size: 22px;
  padding: 20px;
}
.quick_search .searchbar-input::placeholder,
.quick_search .qs-dt ::placeholder,
ion-select .select-placeholder  {
  // ion-select,
  --opacity: 1 !important;
  --placeholder-opacity: 1 !important;
  color:black;
  opacity: 1 !important;
  font-size: 22px;
}
.quick_search .search,
.quick_search .clear {
  position: absolute;
  top: 25px;
  font-size: 1.25em;
  color: grey;
  cursor: pointer;
  z-index: 1;
}
.quick_search .clear {
  left: 25px;
}
.quick_search .search {
  right: 25px;
}
.quick_search .ion-icon,
.quick_search .searchbar-clear-icon {
  display: none !important;
}
.quick_search .filters fa-icon,
.quick_search_filter .filter fa-icon {
  position: absolute;
  left: 17px;
  top: 17px;
}
.quick_search_filter .small-input .left {
  max-width: 80px;
}
.quick_search_filter ion-input {

}
select::placeholder {
  opacity: 1;
}
.quick_search .searchbar-search-icon.sc-ion-searchbar-md {
  display: none;
}
.quick_search .toggles {
  border-bottom: 1px solid #ccc;
}
.quick_search .toggles.checks {
  border-bottom: none;
}
.quick_search .filters {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.quick-search ion-select,
.quick-search ion-datetime,
.quick-search .datetime-placeholder,
.qs-dt {
  border: none !important;
  color: black !important;
}
.qs-dt.rp {
  max-width: calc(100% - 30px);
  margin-left: 30px;
}
.filter .datetime {
  color: grey !important;
  opacity: 0.75 !important;
}
.search-buttons .inner {
  padding: 20px;
}
.search-buttons .search2 {
  --background: #9ab7c0 !important;
}

.quick_search_filter .text_center {
  text-align: center;
}
.quick_search_filter .text_center ion-col {
  max-width: 75px !important;
}
.quick_search_filter .qs-dt {
  text-align: left;
  margin-left: 50px;
  font-size: 20px;
  font-weight: 400;
}

.quick_search.results {
  text-align: center;
  border-radius: 0;
  min-height: 80px;
  font-size: 20px;
  align-items: center;
  border: none;
  box-shadow: none;
  min-height: 285px;
  //border-radius: 10px;
  //margin: 20px 15px;
  //border: 1px solid #9ab7c0;
  //box-shadow: 0px 1px 5px 0px #9ab7c0;
  //border-top-left-radius: 0;
  //border-top-right-radius: 0;
  //margin: 0 25px;
}
.quick_search.results h2,
.quick_search.results p {
  margin: 0;
}
.quick_search.results h3 {
  margin: 5px 0 0 0;
}
.quick_search.results .suggested {
  font-size: 16px;
}
.quick_search.results .res {
  cursor: pointer;
}
.quick_search.results .res:hover {
  background: #9ab7c0;
  color: white;
}

ion-toggle {
  --background: #ccc;
  --background-checked: #9ab7c0;
  --handle-background-checked: #fff;
  // --handle-background-checked: #9ab7c0;
  height: 25px;
  width: 50px;
  --handle-height: 25px;
  --handle-width: 25px;
}
.toggle-pill {
  max-width: 400px;
  margin: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -30px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 20px;
}
.toggle-pill ion-col {
  padding: 10px;
}
.toggle-pill .left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.toggle-pill .right {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.toggle-pill ion-col.selected {
  background: #9ab7c0;
  color: white;
  font-weight: bold;
}

.navbar_link {
  color: white;
}
.modal_normal {
  //--width: 50%;
  --height: 500px;
}
.modal_small {
  //--width: 50%;
  --height: 400px;
}
.ion_no_hover {
  --background-hover: var(--background)
}

.modal_notifications {
  --height: 100vh;
    z-index: 1 !important;
}
.modal_notifications .modal-wrapper {
  right: 0;
  position: absolute;
  top: 75px;
  border-bottom-left-radius: 10px;
}
.modal_notifications .ion-page {
  border-bottom-left-radius: 10px;
}

.helpbot-icon {
  position: fixed;
  bottom: 20px;
  right: 37px;
  background: #ff63c7;
  color: white;
  padding: 25px 20px;
  border-radius: 50%;
  cursor: pointer;
}
.modal_helpbot {
    // z-index: 1 !important;
    ion-backdrop {
      --backdrop-opacity: 0.05;
    }
}
.modal_helpbot .modal-wrapper {
  position: absolute;
  right: 90px;
  bottom: 80px;
  width: 500px;
  height: 600px;
}
.modal_helpbot .ion-page {
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(154, 183, 192);
}

.subscription_tab {
  padding: 5px;
  border-style: solid;
  border-color: white;
  border-top-width: 2px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

ion-datetime {
  border: 1px solid #9ab7c0 !important;
  border-radius: 5px;
}
a.no-decoration {
  text-decoration: none;
}
ion-input,
ion-textarea,
textarea {
  width: 100%;
  border: 1px solid #9ab7c0;
  border-radius: 5px;
  min-height: 50px;
  max-height: unset;
}
textarea {
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}
.mood-icons fa-icon {
  color: grey;
}
.selected-icon {
  color: #ff63c7 !important;
}
.selected-record {
  color: #9ab7c0 !important;
}
.selected-record .wintheme_container {
  border: 4px solid #9ab7c0 !important;
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.mouse {
  cursor: unset !important;
}
.col-md-9-override {
  flex: 0 0 calc(calc(9 / var(--ion-grid-columns, 12)) * 100%) !important;
  width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%) !important;
  max-width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%) !important;
}

/* select list picker */
.alert-wrapper.sc-ion-alert-md {
  min-width: 700px;
  border-radius: 10px;
}
.alert-head.sc-ion-alert-md {
  padding: 8px !important;
}
.alert-button-group .alert-button-role-cancel {
  color: red !important;
}
.alert-button-group .alert-button:last-child {
  font-weight: bold !important;
  color: green !important;
}
/* select list repeat */
.alert-radio-group.sc-ion-alert-md {
  overflow: scroll !important; // doesn't work?!
  border-top: none !important;
}

/* date time picker */
.picker-wrapper {
  top: 0;
  border-radius: 10px !important;
}
.picker-opt.picker-opt-selected {
  font-weight: bold !important;
}
.picker-columns.sc-ion-picker-md {
  height: 200px;
  margin-top: 25px;
}
/* picker toolbar & buttons */
.picker-toolbar-button button {
  font-weight: bold !important;
  color: green !important;
}
.picker-toolbar-cancel button {
  color: red !important;
}
.picker-toolbar.sc-ion-picker-md {
  position: fixed;
  bottom: 0;
}

.modal-wrapper.sc-ion-modal-md {
  overflow: visible;
  border-radius: 10px;
}
.modal-wrapper ion-datetime {
  border: none !important;
}
.modal-wrapper fa-icon {
  margin-right: 15px;
}
app-create-win-theme {
  height: 750px;
  width: 700px;
  margin-top: -150px;
}
app-create-group,
app-create-objective {
  height: 635px;
  margin-top: -100px;
}
app-create-win-theme video {
  width: 100%;
}
app-set-reminder {
  height: 650px;
}
app-edit-license {
  height: 680px;
}
app-set-content-activity {
  height: 450px;
}
app-set-reminder,
app-report-bug,
app-send-question,
app-create-group,
app-create-objective,
app-testimonial-invite,
app-edit-license,
app-set-content-activity {
  border-radius: 10px;
}
.quick_search .searchbar-search-icon.sc-ion-searchbar-md {
  display: none !important;
}
.breadcrums {
  margin-top: 25px;
  padding: 13px 30px 15px;
  align-items: center;
  color: #0f3737;
  background-color: white;
  border: none;
  border-radius: 15px;
  margin: 30px 30px 0 30px;
}
.breadcrums span {
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}
.breadcrums fa-icon {
  font-size: 25px;
}
.breadcrums .learn-more {
  display: inline;
  font-weight: 600;
  font-size: 22px;
  color: #ff63c7;
}
.selectedFilter,
.selectedFilter span,
.selectedFilter fa-icon,
.selectedTab,
.selectedTab fa-icon {
  font-weight: 500;
  color: #0f3737 !important;
}
.selectedTab .heading {
  font-weight: 600;
}

.dueTask,
.dueTask span,
.dueTask fa-icon {
  color: red !important;
}

.unopenedThread {
  background-color: whitesmoke;
}

.selectedThread {
  background-color: aliceblue;
}

.selectedType {
  --background: white;
}

.unselectedType {
  --background: whitesmoke;
}

.content-container {
  background: white;
}
.reminder-modal .alert-wrapper.sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  display: none !important;
}
.reminder-modal .alert-wrapper.sc-ion-alert-md .alert-radio-label {
  padding: 20px !important;
}
div.beta {
  background: yellow;
  padding: 1px 6px 2px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 12px;
  max-width: 100px;
  text-align: center;
}
.user:hover {
  //background: blue;
  font-weight: bold
}
.repeat-delete,
.items-center,
.items-center ion-col {
  text-align: center;
  display: grid;
  align-items: center;
}
.text-left {
  text-align: left;
}
.repeat-delete fa-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}
.select-icon,
.select-icon-inner,
ion-select::part(icon) {
  display: none;
}
ion-label,
ion-select {
  -webkit-flex: none !important; 
  flex: none !important; 
  text-align: center;
}
.content-win-theme {
  border: 1px solid #9ab7c0 !important;
  padding: 14px 0 !important;
  border-radius: 5px;
  min-height: 60px;
}
ion-select.primary-theme {
  background: white !important;
  font-size: 20px;
  border: 4px solid #9ab7c0 !important;
  font-weight: 500;
  font-size: 18px;
}
ion-select.primary-theme .question {
  display: block;
}
ion-col.primary-theme {
  flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
  width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
  max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
  margin-bottom: 10px;
}
ion-col.primary-theme .items-center,
ion-col.primary-theme .del {
  display: none;
}
ion-col.primary-theme .repeat-delete {
  /*flex: 0 0 calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%) !important;
  width: calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%) !important;
  max-width: calc(calc(1.5 / var(--ion-grid-columns, 12)) * 100%) !important;
  margin-left: 4.16666667% !important;*/
}
/*ion-toggle {
  padding: 5px 0;
  height: 30px;
  width: 60px;
  margin-right: 20px;
}*/
.toggle-icon-wrapper {
  margin-left: 5px;
}
.signup_button_alternative.add {

}
.signup_button_alternative.delete {
  --background: red;
}
.signup_button_alternative.deleteBig {
  --background: red;
}
.signup_button_alternative.approve {
  --background: green;
}
.signup_button_alternative.copy {
  --background: orange;
}
.signup_button_alternative.primary {
  --background: #9ab7c0 !important;
}
.horizontal-list {
  flex-wrap: nowrap;
  overflow: auto;
  overflow-y: hidden;
}
.horizontal-list ion-col {
  max-width: 200px;
}
.favourite,
.unfavourite {
  font-size: 20px;
  color: grey;
  cursor: pointer;
}
.unfavourite {
  color: grey;
  position: absolute;
  right: 15px;
  top: -30px;
  display: none;
}
.favourite {
  position: absolute;
  right: 10px;
}
.favourite.active {
  color: #9ab7c0;
}
.favourite img,
.horizontal-list img {
  max-width: 100px;
  max-height: 100px;
}

.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
}
.reminders .alert-radio-icon.sc-ion-alert-md {
  display: none;
}
.box-shadow {
  border: 1px solid #9ab7c0;
  box-shadow: 0px 1px 5px 0px #9ab7c0;
}
.session-profile {
  color: #0f3737;
  text-align: left;
  padding-left: 30px;
}
.text-blue {
  color: #9ab7c0 !important;
}
.text-green {
  color: #0f3737 !important;
}
.dashboard-top {
  background-color: white;
  border-radius: 15px;
  margin-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 10px
}
.dash-message {
  text-align: center;
  margin: 5px 0 15px;
}
.dash-header {
  color: #0f3737;
  font-size: 40px;
  font-weight: bolder;
}
.dash-subtitle {
  font-size: 20px;
}
.dash-daily-thought {
  font-size: 18px;
  max-width: 790px;
  margin: auto;
  background-color: #9ab7c0;
  padding: 20px 10px;
  border-radius: 5px;
  margin-top: 30px;
  box-shadow: 0px 1px 5px 0px #9ab7c0;
  color: white;
  font-weight: bold;
  margin-bottom: -15px;
}
span.pink {
  color: #ff63c7;
  font-weight: bold;
}
span.grey {
  color: grey;
}
.pink-strapline {
  /*color: #ff63c7;
  font-size: 20px;
  font-style: italic;
  font-family: 'Dancing Script';
  font-weight: bold;*/
}
body,
:host {
  color: #0f3737;
}
.heading {
  width: 100%;
  cursor: pointer;
  color: #0f3737;
  font-size: 22px;
}
.subheading {
  width: 100%; 
  color: #0f3737; 
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  //font-weight: bold;
}
fa-icon .times-circle {
  cursor: pointer;
  color: red;
}
fa-icon .fa-thumbs-up,
fa-icon .fa-reply,
fa-icon .fa-comment,
fa-icon .fa-ellipsis-h {
  color: #9ab7c0;
  cursor: pointer;
}
fa-icon.custom svg path {
  fill: #9ab7c0;
  stroke: #0f3737;
  fill-opacity: 0;
  stroke-opacity: 0.9;
  stroke-width: 20;
}
fa-icon.custom.white svg path {
  fill:white;
  stroke: white;
}
fa-icon.solid svg path {
  fill: #9ab7c0;
  stroke: #0f3737;
  fill-opacity: 1;
  stroke-opacity: 0;
  stroke-width: 0;
}
fa-icon.solid.secondary svg path {
  fill: #0f3737 !important;
}
fa-icon.outline svg path {
  stroke: #fff;
  fill-opacity: 0;
  stroke-opacity: 0.9;
  stroke-width: 20;
}
fa-icon.outline.white svg path {
  fill:white;
  stroke: white;
}
.complete-task {
  font-size: 1.5em;
}
.selectedTab fa-icon.custom svg path {
  fill-opacity: 1;
  stroke-opacity: 0;
  fill:  #0f3737;
}
.readonly {
  pointer-events: none;
  opacity: 0.5;
}
@keyframes blinker {  
  to { opacity: 0; }
}
.blink {
  animation: blinker 0.4s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
.kebab {
  position: absolute;
  right: 0;
  background: white;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-top-right-radius: 0;
  padding: 5px;
}
fa-icon.green,
fa-icon.primary,
fa-icon.pink,
fa-icon.red {
  cursor: pointer;
}
fa-icon.green svg path {
  fill: rgba(0,128,0,0.7);
  stroke: rgba(0,128,0,0.7);
}
fa-icon.primary svg path {
  fill: #9ab7c0;
  stroke: #9ab7c0;
}
fa-icon.pink svg path {
  fill: #ff63c7;
  stroke: #ff63c7;
}
fa-icon.grey svg path {
  fill: grey;
  stroke: grey;
}
fa-icon.white svg path {
  fill: #fff;
  stroke: #fff;
}
fa-icon.grey-solid {
  color: darkgrey;
}
fa-icon.grey-solid svg {
  color: unset !important;
}
fa-icon.orange svg path {
  fill: rgba(255,165,0,0.7);
  stroke: rgba(255,165,0,0.7);
}
fa-icon.red svg path {
  fill: rgba(255,0,0,0.7);
  stroke: rgba(255,0,0,0.7);
}
.tools {
  margin-top: 30px;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.tools span {
  display: block;
  font-size: 17px;
  max-width: 100px;
  line-height: 1;
  margin-top: 10px;
  cursor: pointer;
}
.tools fa-icon {
  cursor: pointer;
}
.tools.single {
  text-align: center;
}
.tools.single span {
  max-width: unset;
}
.tile-container {
  align-items: end;
  display: grid;
}
.dashboard-tiles {
  // align-items: center;
  text-align: center;
}
.dashboard-tiles .tile {
  padding: 10px;
}
.dashboard-tiles .tile.half div.container {
  min-height: 230px;
  margin-bottom: 45px
}
.dashboard-tiles .tile div.container {
  // align-items: center;
  // display: grid;
  border-radius: 10px;
  min-height: 380px;
  box-shadow: 0px 1px 5px 0px #9ab7c0;
  font-weight: 600;
}
.dashboard-tiles .tile div span.title {
  display: block;
  position: absolute;
  top: 20px;
  text-align: center;
  width: calc(100% - 20px);
  font-size: 20px;
}
.dashboard-tiles .tile div .cont {
  padding-top: 60px;
}
.dashboard-tiles .tile div .cont.interactions {

}
.dashboard-tiles .tile div .cont.interactions span {
  margin-left: 20px;
  font-size: 40px;
}
.dashboard-tiles .tile div .cont.interactions fa-icon svg path {

}
.dashboard-tiles span {
  
}
.dashboard-tiles ion-col.repeat {
  padding-bottom: 0;
}
.chart.content-activity {
  height: 350px;
}
.chart.objectives-activity {
  height: 280px;
}
.chart.featured-objectives {
  height: 115px;
}
.dashboard-gauges {
  align-items: center;
}
.gauge.left,
.gauge.right {
  height: 300px;
}
.gauge.center {
 height: 400px; 
}
.dashboard-gauges .label {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: -40px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.dashboard-gauges .label.chart {
  margin-top: -20px;
}
.dashboard-gauges .label.center {
  margin-top: -90px;
}

.alert-counts .alert {
  margin-bottom: 50px;
}
.counter {
  background: red;
  border-radius: 50px;
  padding: 1px 7px;
  position: absolute;
  right: 10px;
  top: 0;
  font-weight: bold;
  color: white;
}

.traffic-light {
  width: 80px;
  border: 1px solid;
  border-radius: 10px;
}
.traffic-light .light {
  height: 50px;
  margin: 10px auto;
  border: 1px solid;
  border-radius: 50px;
  width: 50px;
}

.img {
  max-height: 90px;
}
.img.small {
  max-height: 50px;
}
.img-placeholder {
  opacity: 0.5; 
  padding: 15px;
}
.img-placeholder.photo {
  max-height: 230px !important;
  padding: 50px;
  margin: auto;
}
.img-placed {
  width: 100%;
}
.tab-content .img-placed {
  width: auto;
  max-height: 300px;
  width: auto;
}
.theme-photo {
  height: 300px;
  width: auto;
  max-width: 100%;
}
.admin-head {
  margin-bottom: -20px;
  margin-top: 10px;
}
.admin-head .user img {
  width: auto;
  border-radius: 5px;
  max-height: 100px;
}
.admin-head .company img {
  width: auto;
  border-radius: 5px;
  max-height: 100px;
}
.admin-head label {
  padding: 15px 30px !important;
}
.percentage {
  height: 30px;
  width: 300px;
  margin: auto;
  background: white;
  border-radius: 10px;
  border: 1px solid;
  margin-bottom: 10px;
  float: left;
}
.percentage .inner {
 background: #9ab7c0;
 height: 100%;
 float: left;
 border-radius: 10px; 
}
.percentage-red .inner {
 background: red;
}
.percentage-green .inner {
  background: green;
}
.capacity {
  float: left;
  margin-left: 20px;  
}

/* TABS & FORMS */
.space-top {
  margin-top: 15px
}
.tab-row {
  justify-content: center; 
  text-align: center;
  padding: 0 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}
.tab-row .tab ion-item {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.tab-title {
  width: 100%; 
  cursor: pointer; 
  color: grey; 
  font-size: 22px;
}
.tab-content {
  justify-content: center; 
  text-align: center; 
  background: whitesmoke; 
  padding: 0px; 
  border-radius: 9px;
  margin-top: 0 !important;
}
.tab-inner-container {
  margin-bottom: 25px; 
  border-bottom: 1px solid black;
}
.form-heading {
  color: grey; 
  font-size: 22px;
}
.form-group {
  margin-top: 15px;
  text-align: left;
}
.form-group.label {
  cursor: pointer; 
  color: grey; 
  font-size: 22px;
}
.form-group.textarea-container {
  padding: 5px;
}
.toggle .left {
  border-left: 5px solid #9ab7c0;
  padding-left: 10px;
  height: 35px;
  display: grid;
  align-items: center;
}
fa-icon.profile-photo {
  display: block;
  margin-bottom: 30px;
}
.data fa-icon.profile-photo {
  margin: 10px 0 !important;
}
.edit-permission {
  display: grid;
  align-items: center;
  cursor: pointer;
}
.edit-permission fa-icon {
  padding-left: 10px;
}
.has-header .profile-photo,
ion-header .profile-photo {
  display: inline;
}
ion-header .profile-photo {
  display: none;
}
.has-header .profile-photo img,
.has-header .profile-photo fa-icon ,
ion-header .profile-photo img,
ion-header .profile-photo fa-icon {
  width: 55px; 
  height: 55px; 
  border-radius: 50%; 
}
ion-header .hamburger,
ion-header .logo {
  display: none;
}
ion-header .version {
  color: white; 
  display: inline-block; 
  position: relative; 
  bottom: -6px; 
  left: 30px; 
  font-weight: bold;
}
.menu-tab {
  height: 100%; 
  padding: 25px 25px 25px 25px;
}
.opacity-50 {
  opacity: 50%;
}
.bold {
  font-weight: bold;
}
.repeat-table .header {
  margin-bottom: 20px;
}
.repeat-table .data {
  border-top: 2px solid #9ab7c0;
  margin-top: 5px;
}
.repeat-table .data.padded {
  padding: 10px 0;
}
.repeat-table .data img {
  width: 80px;
  border-radius: 50px;
}
.repeat-table .data.reminder {
  text-align: left;
  padding: 10px 0;
}
.m-auto {
  margin: auto;
}
.space-top,
.space-top-title {
  margin-top: 50px;
}
.space-top-title {
  margin-bottom: 25px;
}
.payment-icon {
  max-width: 60px;
}
.payment-img {
  border-radius: 0 !important;
}
.subscriptions {
  margin: 50px;
}
.subscriptions .level {
  padding: 0 15px;
}
.subscriptions .level .inner {
  padding: 50px 0;
  border-radius: 15px;
  height: 100%;
}
.subscriptions .level .inner h2 {
  font-weight: bold;
  margin-bottom: 0;
}
.subscriptions .level .inner h4 {
  margin: 0;
}
.subscriptions .level .inner h5 {
  margin: 20px;
}
.subscriptions .level .inner h5 span {
  font-weight: bold;
  font-size: 30px;
}
.subscriptions .level .inner .button {
  padding: 0 20px;
}
.delete {
  color: red;
  cursor: pointer;
  float: right;
}
.delete.contributor {
  position: absolute;
  top: 0;
  right: calc(50% - 50px);
}
.deleteBig {
  cursor: pointer;
}
.float-none {
  float: none !important;
}
.tab-content.attachments {
  /*margin-top: 5px;*/
  margin-top: 0 !important;
}
.testimonial {
  border-bottom: 1px solid black; 
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.testimonial .delete {
  position: absolute;
  right: 15px;
  top: 15px;
}
.attachments .attachment {
  padding: 20px 15px;
  border-radius: 10px;
  margin: 30px 10px;
  min-height: 290px;
}
.attachments .attachment:before {
  content:'';
  border: 2px solid #9ab7c0;;
  background-color:inherit;
  position:absolute;
  height: 27px;
  width: 100px;
  top: -30px;
  left: 11px;
  border-bottom: 0;
  border-right: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.attachments .attachment:after {
  content:'';
  background-color:inherit;
  position:absolute;
  border: 2px solid #9ab7c0;;
  width: 35px;
  height: 40px;
  transform: rotate(45deg);
  top: -23px;
  left: 90px;
  border-left: 0;
  border-bottom: 0;
  border-right:0;
}
.attachments ion-row.full {
  height: 100%;
}
.attachments .attachment .title {
  width: calc(100% - 50px);
  float: left;
  border: none;
  border: 1px solid #9ab7c0;;
}
.attachments .attachment .delete {
  margin-top: 12px;
  position: absolute;
  right: 5px;
}
.attachments .attachment .label {

}
.attachments .attachment span.new {
  display: block;
  font-size: 16px;
}
.attachments .attachment .file .file-preview {
  min-height: 120px;
  align-items: center;
  display: grid;
}
.attachments .attachment .new-file {
  padding: 12px 12px 12px;
  border: 1px dashed #9ab7c0;
  margin: 5px;
  border-radius: 5px;
  display: grid;
  margin-top: 15px;
  margin-bottom: 15px;
}
.attachments .attachment .new-file-big {
  padding: 12px 12px 12px;
  border: 1px dashed #9ab7c0;
  margin: 5px;
  height: 150px;
  border-radius: 5px;
  display: grid;
  margin-top: 15px;
  margin-bottom: 15px;
}
.attachments .attachment .delete-file {
  color: red;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.hover-list {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 999;
  margin-top: 10px;
}

.build a {
  color: black;
}
.build .logout {
  cursor: pointer;
}
.build .logout ion-col {
  align-items: center;
  display: grid;
}


.toggled {
  color: #ff63c7 !important;
  font-weight: bold;
}

.reward-badges ion-col {
  filter: grayscale(100%);
  opacity: 0.5;
}
.reward-badges ion-col.achieved {
  filter: none !important;
  opacity: 1 !important;
}
.reward-badges .badge {
  margin: auto;
  width: 60px;
  z-index: 1;
}
.reward-badges .badge-star {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 30px;
  left: calc(50% - 12px);
  opacity: 0.5;
}
.reward-badges .badge-star.blue {
  background: #9ab7c0;
}
.reward-badges .badge-star.red {
  background: red;
}
.reward-badges .badge-star.orange {
  background: orange;
}
.reward-badges .badge-star.green {
  background: green;
}
.reward-badges .badge-star.pink {
  background: pink;
}
.reward-badges span {
  text-align: center;
  margin: auto;
}

.bottom-30 {
  margin-bottom: 30px !important;
}
.bottom-20 {
  margin-bottom: 20px !important;
}
.bottom-10 {
  margin-bottom: 10px !important;
}
.bottom-5 {
  margin-bottom: 5px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-30 {
  padding-top: 30px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-20 {
  padding: 20px !important;
}
.m-0 {
  margin: 0 !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-20 {
  margin: 20px !important;
}

/* row */
.members {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 4px 2px -2px #9ab7c0;
  margin-bottom: 10px;
}
.members span {
  color: grey; 
  font-size: 20px;
}
/* col-2 */
.members .image img {
  border-radius: 50%;
  max-height: 75px;
  float: left;
  width: auto;
  min-width: 75px;
}
.members .image img.radius-none {
  border-radius: 0 !important;
}
.members .image fa-icon {
  float: left;
  font-size: 20px;
}
/* col-3 */
.members .name {
  text-align: left; 
  align-self: center;
}
/* col-3 */
.members .email {
  text-align: left; 
  place-self: center;
}
/* col-2 */
.members .role {
  text-align: left; 
  place-self: center;
}
/* col-2 */
.members .remove {
  text-align: left; 
  align-self: center;
}
.members .remove fa-icon {
  float: left;
}

.tag-inputs {
  height: 52px;
  overflow: hidden;
}

.associated-attachment {
  padding: 20px;
}

.tooltip {
  position: fixed;
  margin-top: 25px;
  margin-left: 250px;
  max-width: 400px;
  background: #9ab7c0;
  color: white;
  padding: 10px 12px 12px;
  font-weight: bold;
  border-radius: 5px;
  visibility: hidden;
}
.toolbar-tooltip {
  display: none;
  position: unset;
  border-radius: 12px;
  text-align: center;
  margin: 0;
  padding: 7px 0;
  background: #9ab7c0;
  color: white;
  font-weight: bold;
  margin: 10px auto -15px;
  width: fit-content;
  padding: 7px;
}

fa-icon .fa-info-circle {
  cursor: pointer;
}

/* LOADER */
.loading .sc-ion-loading-md {
  background: white;
  --backdrop-opacity: 0.95;
  border-radius: 5px;
}

/* TOAST */
@keyframes fadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}
.toast-container {
  width: 600px;
  background: white;
  position: fixed;
  top: 60px;
  right: calc(50vw - 100px);
  border-radius: 10px;
  height: 120px;
  box-shadow: -1px 5px 15px 1px grey;
  opacity: 0.97;
  cursor: default;
  animation: fadeIn 3s;
  z-index: 9999999;
  visibility: visible;
}
.toast-hide {
  -webkit-animation: fadeOut 3s;
  animation: fadeOut 3s;
}
.toast-head {
  font-size: 14px;
}
.fixed-translate {
  webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.toast-head .right {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 18px;
  display: none;
}
.toast-body {
  display: flex;
}
.toast-body .left {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  height: 120px;
  text-align: center;
  padding-top: 20px;
}
.toast-body .left .icon {
  color: white;
  font-size: 60px;
}
.toast-body .left .checkmark {
  font-family: arial;
  -ms-transform: scaleX(-1) rotate(-35deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-35deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-35deg);
}
.toast-body .left .exclamation {
  
}
.toast-body .right {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
  height: 120px;
  padding: 10px 20px;
}
.toast-body .right .heading {
  font-weight: bold;
  font-size: 20px;
}
.toast-body .right .message {
  font-size: 18px;
  margin-top: 5px;
}

.invoice-status {
  background: #9ab7c0;
  border-radius: 5px;
  width: max-content;
  padding: 4px 10px 6px;
  color: white;
  margin: auto;
  text-transform: capitalize;
  font-weight: bold;
}